.table::-webkit-scrollbar {
  width: 5px!important;
  height: 6px!important;
  border: none!important; 
}
.table td span::-webkit-scrollbar {
  width: 5px!important;
  height: 6px!important;
  border: none!important; 
}
.table::-webkit-scrollbar-track {
  background: #ddd!important;
  border: none!important; 
}

table{
  background-color: transparent!important;
  width: 100%;
  height: 100%;
  border-spacing: 0!important;
  border: 1px solid #f1f1f1!important;
  border-radius: 5px!important;
  transform: rotateX(180deg);
}

.table { 
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  cursor: pointer;
  background-color: #fff!important;
  color: rgba(0, 0, 0, 0.87)!important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
  border-radius: 5px!important;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)!important;
  font-size: 13px!important;
  position: relative;
  transform: rotateX(180deg);
}
  
tbody {
  font-size: 13px!important;
}

thead {
  font-size: 13px!important;
  text-transform: capitalize;
  background: #ffffff!important;
  color: #757575!important;
  border-radius: 5px 5px 0 0!important;
}
  
tr {
  border-top: 1px solid #e6e6e6!important;
}
  
/* tr:hover {
  background-color: #f5f8fc!important;
} */

th{
  color: #333!important;
  white-space: break-spaces!important;
}

td {
  font-weight: 100;
  padding: 0.66rem 0.6rem!important;
}

th, td {
  /* display: inline-flex;
  align-items: center;
  justify-content: center; */
  margin: 0;
  padding: 0.6rem; 
  width: 120px; 
  /* border-bottom: 1px solid #ddd; */
  border-right: 1px solid #ddd!important;
  font-size: 13px!important;
  text-align: center!important;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
  
td:first-child,
th:first-child { 
  width: auto;
  /* display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;  */
  color: #757575!important;
  position: sticky;
  left: 0;
  z-index: 10000;
  background-color: #ffffff!important;
}

td:nth-child(2),
th:nth-child(2) { 
  width: 130px;
  overflow: visible;
  padding: 0.66rem 0%!important;
  position: sticky;
  left: 2.5rem;
  z-index: 10000;
  background-color: #ffffff!important;
}

td:nth-child(3),
th:nth-child(3) { 
  /* width: 100px!important; */
}

td:nth-child(4),
th:nth-child(4) { 
  /* width: 100px!important; */
}

td:nth-child(8),
th:nth-child(8) { 
  width: 150px!important; 
}

/* td:nth-last-child(2),
th:nth-last-child(2) {
  text-align: right;
} */
  
tr:last-child td {
  border-bottom: 0;
}
th:last-child, td:last-child {
  border-right: 0!important;
}

tr:nth-child(even) {background-color: #f2f2f2}

td + td:not(:nth-last-child(2)) {
  text-align: center!important;
}
  
.table__button-group {
  visibility: hidden;
  transition: visibility 0.06s ease-in;
}
.table__button-group a {
  text-decoration: none;
  color: #0989ab!important;
  font-size: 0.8rem;
  font-weight: 500;
}
.table__button-group a:hover {
  color: #0989ab!important;
  opacity: 0.75;
}
.table__button-group a ~ a:before {
  content: "|";
  padding: 0 0.35rem;
}

tr:hover > td .table__button-group {
  visibility: visible;
}

@media (max-width: 800px) {
  .table__button-group {
    visibility: visible;
  }
}
 
.pagination{
  font-size: 13px!important;
  line-height: 1.5;
  color: #636e75!important;  
  overflow: visible;
  width: 15%!important;
  left: 70%!important;
}

.pagination button{
  font-size: 13px!important;
  color: #6f7071!important;
  border: none!important;
  background: transparent!important;
  cursor: pointer;
}
 
input[type='checkbox']{
  width: 13px!important;
  height: 13px!important;
  cursor: pointer;
}